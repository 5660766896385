<template>
  <CRow>
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
                ref="reactionsTable" 
                :data="reactionDetails" 
                :striped="true" 
                :hoverable="true" 
                :mobile-cards="true" 
                :paginated="isPaginated" 
                :per-page="perPage" 
                :current-page.sync="currentPage" 
                :pagination-simple="isPaginationSimple" 
                :pagination-position="paginationPosition"
                default-sort-direction='desc'
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="reply_created">

        <template slot-scope="reaction">
          <b-table-column field="profile" label="" width="5%">
            <userProfilePopover ref="userProfilePopover"
                                mode="user"
                                :userName="reaction.row.reply_user_name"
                                :userProfileImage="reaction.row.reply_user_profile_image" 
                                :userIdExternal="reaction.row.reply_user_id_external" 
                                :senderIdExternal="null"
                                popoverPlacement="right"/>
          </b-table-column>                  
          <b-table-column field="name" :label="$t('common.Employee')" :searchable="searchEnabled" width="25%">
            <span>{{reaction.row.reply_user_name}}</span>
          </b-table-column>
          <b-table-column field="name" :label="$t('common.Reaction')" width="40%">
            <span>{{reaction.row.reply_content}}</span>
          </b-table-column>
          <b-table-column field="reply_created" :label="$t('common.Reacted_at')">
            <span>{{reaction.row.reply_created | moment('YYYY-MM-DD HH:ss')}}</span>
          </b-table-column>                                
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('insights.No_reactions_yet')}}</span>
          </div>
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'LikesGiven',
  props: ['reactionCount', 'reactionDetails'],
  components: {
    userProfilePopover
  },
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small'
    }
  },
  mounted:function() {
    // Check if the pagination should be activated
    (this.reactionCount > 0) ? this.isPaginated = true : this.isPaginated = false;
  }
}
</script>
