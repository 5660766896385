<template>
  <CRow class="reachability">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
                ref="departmentTable" 
                :data="departmentData" 
                :striped="true" 
                :hoverable="true" 
                :mobile-cards="true" 
                :paginated="departmentsPaginated" 
                :per-page="departmentsPerPage" 
                :current-page.sync="currentPage" 
                :pagination-simple="isPaginationSimple" 
                :pagination-position="paginationPosition" 
                detailed 
                detail-key="name" 
                :show-detail-icon="true"
                :default-sort-direction="'desc'"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="seen_percentage">

        <template slot-scope="department">
          <b-table-column field="name" sortable :label="$t('common.Department')" :searchable="searchEnabled" width="25%">
            <span>{{department.row.name}}</span>
          </b-table-column>
          <b-table-column field="count" sortable :label="$t('insights.Count')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{department.row.count}}</span>
              <i class="icon top far fa-user ml-1"/>
            </div>
          </b-table-column>
          <b-table-column field="count_seen" sortable :label="$t('insights.Count_seen')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{department.row.count_seen}}</span>
              <i class="icon top far fa-user ml-1"/>
            </div>
          </b-table-column>
          <b-table-column field="seen_percentage" sortable :label="$t('insights.Reach')" width="45%">
            <CProgress :max="100" show-value>
              <CProgressBar :value="department.row.seen_percentage"></CProgressBar>
              <span>{{parseFloat(department.row.seen_percentage).toFixed(0)}}%</span>
            </CProgress>
          </b-table-column>              
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('insights.No_departments_reached')}}</span>
          </div>
        </template> 
        <template slot="detail" slot-scope="department">
          <b-table class="data_table" 
                    :data="Object.values(department.row.teams)" 
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="teamsPaginated" 
                    :per-page="teamsPerPage" 
                    :current-page.sync="currentPage" 
                    :pagination-simple="isPaginationSimple" 
                    :pagination-position="paginationPosition" 
                    detailed 
                    detail-key="name" 
                    :show-detail-icon="true" 
                    :default-sort-direction="'desc'"
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="seen_percentage">
            
            <template slot-scope="team">
              <b-table-column field="name" sortable :label="$t('common.Team')" :searchable="searchEnabled" width="25%">
                <span>{{team.row.name}}</span>
              </b-table-column>
              <b-table-column field="count" sortable :label="$t('insights.Count')" width="15%">
                <div class="d-flex align-items-center count">
                  <span>{{team.row.count}}</span>
                  <i class="icon top far fa-user ml-1"/>
                </div>
              </b-table-column>
              <b-table-column field="count_seen" sortable :label="$t('insights.Count_seen')" width="15%">
                <div class="d-flex align-items-center count">
                  <span>{{team.row.count_seen}}</span>
                  <i class="icon top far fa-user ml-1"/>
                </div>
              </b-table-column>
              <b-table-column field="seen_percentage" sortable :label="$t('insights.Reach')" width="45%">
                <CProgress :max="100" show-value>
                  <CProgressBar :value="team.row.seen_percentage"></CProgressBar>
                  <span>{{parseFloat(team.row.seen_percentage).toFixed(0)}}%</span>
                </CProgress>
              </b-table-column>                                                                 
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <span>{{$t('insights.No_teams_reached')}}</span>
              </div>                       
            </template>
            <template slot="detail" slot-scope="team">
              <b-table class="data_table" 
                        :data="Object.values(team.row.employees)" 
                        :striped="true" 
                        :hoverable="true" 
                        :mobile-cards="true" 
                        :paginated="employeesPaginated" 
                        :per-page="employeesPerPage" 
                        :current-page.sync="currentPage" 
                        :pagination-simple="isPaginationSimple" 
                        :pagination-position="paginationPosition"
                        default-sort-direction='desc'
                        :sort-icon="sortIcon"
                        :sort-icon-size="sortIconSize"
                        default-sort="name">
                
                <template slot-scope="employee">                        
                  <b-table-column field="profile" label="" width="5%">
                    <userProfilePopover ref="userProfilePopover"
                                        mode="user"
                                        :userName="employee.row.name"
                                        :userProfileImage="employee.row.profile_image" 
                                        :userIdExternal="employee.row.user_id_external"
                                        :senderIdExternal="null"
                                        popoverPlacement="right"/>
                                        
                  </b-table-column>                          
                  <b-table-column field="name" sortable :label="$t('common.Employee')" :searchable="searchEnabled">  
                    <span>{{employee.row.name}}</span>
                  </b-table-column>
                  <b-table-column field="function" sortable :label="$t('common.Function')" :searchable="searchEnabled">
                    <span>{{employee.row.function}}</span>
                  </b-table-column>
                  <b-table-column field="read_on" sortable :label="$t('insights.Has_seen_post')">
                    <div class="has_seen_post_status">
                      <i class="fas" v-bind:class="{'fa-check mr-2' : employee.row.read_on !== null, 'fa-times' : employee.row.read_on === null}"></i>
                      <span v-if="employee.row.read_on !== null"><em>@ {{employee.row.read_on | moment('YYYY-MM-DD HH:ss')}}</em></span>
                    </div>
                  </b-table-column>                                                
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <span>{{$t('insights.No_employees_reached')}}</span>
                  </div>                
                </template>                
              </b-table>                      
            </template>
          </b-table>
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'ReachabilityOrganisation',
  props: ['departments'],
  components: {
    userProfilePopover
  },
  data() {
    return {
      departmentData: [],
      departmentsPaginated: false,
      teamsPaginated: false,
      employeesPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      departmentsPerPage: 15,
      teamsPerPage: 10,
      employeesPerPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false
    }
  },
  mounted:function() {
    // Set the prop as the table data
    this.departmentData = Object.values(this.departments);
  }
}
</script>
