<template>
  <div class="h-100 d-flex flex-column align-items-center justify-content-center social_vote_result">
    <div class="w-100 d-flex flex-column align-items-center">
      <span class="total_votes">{{ socialVoteResult.total_votes | abbr}}</span>
      <span class="mt-2">{{$t('common.Votes')}}</span>
    </div>
    <div class="w-100 mt-2 d-flex flex-row">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <span class="material-icons">thumb_down</span>
        <span class="down_votes ml-2">{{ socialVoteResult.down_votes | abbr}}</span>
      </div>
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <span class="material-icons">thumb_up</span>
        <span class="up_votes ml-2">{{ socialVoteResult.up_votes | abbr}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocialVote',
  props: ['socialVoteResult'],
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  }
}
</script>
