<template>
  <div class="h-100 d-flex flex-column">
    <div class="post_likes_container">
      <CRow v-if="likes.length > 0">
        <CCol cols="2" xl="2" lg="3" md="4" sm="6"
              v-for="like in currentPageLikes[currentPage - 1]"
              v-bind:key="like.index"
              class="text-center post_like">

          <userProfilePopover ref="userProfilePopover"
                              mode="user"
                              :userName="like.like_user_name"
                              :userProfileImage="like.like_from_user_profile_image"
                              :userIdExternal="like.like_from_user"
                              :senderIdExternal="null"
                              popoverPlacement="right"/>
          <span class="like_type" v-html="getTwemoji(like.like_type)"></span>
        </CCol>          
      </CRow>
      <CRow v-else>
        <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="p-0">
          <div class="p-2 text-center">
            <span>{{$t('insights.No_likes_yet')}}</span>
          </div>
        </CCol>
      </CRow>        
    </div>
    <div v-if="likePages > 1">
      <hr class="m-0">
      <CRow class="m-0">
        <CCol cols="12" md="12" class="pb-0 pr-0 pl-0">
          <v-pagination class="justify-content-end" v-model="currentPage" :length="likePages" :total-visible="3" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'Likes',
  props: ['likes'],
  components: {
    userProfilePopover
  },
  data() {
    return {
      currentPage: 1,
      currentPageLikes: {},
      likesPerPage: 18,
      likePages: 0,
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },  
  mounted:function() {
    if(this.likes) {
      // Reset the likePages value
      this.likePages = 0;
      // Paginate likes
      for (let i = 0; i < this.likes.length; i = i + this.likesPerPage) {
        this.currentPageLikes[this.likePages] = this.likes.slice(i, i + this.likesPerPage);
        this.likePages++;
      }
    }
  }     
}
</script>
